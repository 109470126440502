import { defaultOptionsKate } from "./zubar";

export const maminka = {
  title: "Maminka",
  storyNumber: 3,
  story: [
    {
      content: `Jste v supermarketu a nakupujete pro celou rodinu. Košík je téměř plný.`,
      image: "story/maminka/paprika.png",
      options: [{ text: "Pokračovat", type: "next" }],
    },
    {
      // kateText: "Teď už jen zaplatit a rychle domů.",
      // mp3: "story/records/1a.mp3",
      image: "story/maminka/ananas.png",
      content: `Po vystání dlouhé řady jste u placení. Konečně! Teď už jen zaplatit a rychle domů.

Chcete platit kartou, protože hotovost padla na kapesné pro děti. Ale terminál, zdá se, zrovna hlásí chybu karty! Co teď?`,
      options: [
        {
          text: "Vrátit nákup a odejít",
          type: "story",
          story: {
            image: "story/maminka/2.jpg",
            content: `## Stornovat a vrátit

Vybavíte si prázdnou lednici a tři hladové krky. Ten nákup prostě musíte domů donést.`,
            options: [{ text: "Pokračovat", type: "back" }],
          },
        },
        {
          text: "Zkusit poprosit o pomoc Kate",
          type: "next",
        },
      ],
    },
    {
      mp3: "story/records/maminka1_v2.mp3",
      kateText: "A jéje tahle platba je vyšší než váš týdenní limit, co ho teď spolu navýšit.",
      chat: {
        src: "story/Limit.webm",
      },
      // mp3: "story/records/1a.mp3",
      // image: "story/maminka/1.jpg",
      content: `Vytáhnete mobil, že se podíváte do Smartu, a na obrazovce už vás Kate upozorňuje na překročení limitu karty. No jistě, vždyť včera jste dětem zaplatila kroužky a Kate už vás přece upozornila, že se blížíte k jeho vyčerpání. `,
      options: [
        {
          text: "Ukončit ukázku Kate",
          type: "next",
        },
      ],
    },
    {
      content: "Kate vám pomůže navýšit limit mžiknutím oka. Platba teď prošla bez problému! Dobrá práce.",
      image: "story/maminka/1.jpg",
      // mp3: "story/records/4a.mp3",
      kateText: "Zaujala jsem vás? Najdete mě v aplikaci ČSOB Smart.",
      mp3: "story/records/konec pro student_maminka_zubar.mp3",
      options: defaultOptionsKate,
    },
  ],
};
