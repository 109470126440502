export const defaultOptionsKate = [
  { text: "Zpět do hlavní nabídky", type: "exit" },
  { text: "Více o Kate", type: "kate" },
];

export const zubar = {
  title: "Zubař",
  storyNumber: 2,
  story: [
    {
      image: "story/zubar/1.jpg",
      content: `Pacientovi právě odstraňujete zubní kaz. Začne zvonit telefon. Sestřička si zrovna musela odskočit a nevrací se.

Pacient je neklidný, protože hlasité zvonění neustává. Musíte to vyřešit.

Volá manželka Soňa a podle hromady zpráv je to naléhavé. Zvednete to.`,
      options: [{ text: "Pokračovat", type: "next" }],
    },
    {
      image: "story/zubar/4.jpg",
      content: `Manželka s pláčem říká, že nemá peníze na účtu a zrovna stojí u pokladny v supermarketu. Máte jí rychle nějaké poslat. `,
      kateText: "Bez starostí. S touto situací vám snadno pomůžu.",
      mp3: "story/records/zubar1.mp3",

      options: [
        {
          text: "Odeslat platbu z mobilního bankovnictví",
          type: "story",
          story: {
            image: "story/zubar/7.jpg",
            content: `Pacient se vrtání bojí a pokud zákrok přerušíte hrozí, že vám z křesla uteče. Své ženě musíte pomoct. Jak to ale udělat? Potřebujete volné ruce!`,
            options: [
              {
                text: "Požádat Kate o pomoc",
                type: "story",
                story: {
                  // image: "story/zubar/7.jpg",
                  content: `Klepnutím jen otevřete Smart a dalším klepnutím Kate. Pak už můžete vše ovládat hlasem. Řeknete třeba „Kate, chci poslat peníze.“ Nebo rovnou „Chci poslat 2 000 Soně.“

  Teď už jen dokončit pacientovu plombu a můžete se těšit na čerstvou večeři v rodinném kruhu.`,
                  chat: {
                    src: "story/Poslat.webm",
                  },
                  options: [
                    {
                      text: "Ukončit ukázku Kate",
                      type: "next-slide",
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          text: "Požádat Kate o pomoc",
          type: "story",
          story: {
            image: "story/zubar/7.jpg",
            content: `Klepnutím jen otevřete Smart a dalším klepnutím Kate. Pak už můžete vše ovládat hlasem. Řeknete třeba „Kate, chci poslat peníze.“ Nebo rovnou „Chci poslat 2 000 Soně.“

Teď už jen dokončit pacientovu plombu a můžete se těšit na čerstvou večeři v rodinném kruhu.`,
            chat: {
              src: "story/Poslat.webm",
            },
            kateText: "Jdeme na to! Zaplatit spolu zvládneme za minutku.",
            mp3: "story/records/zubar2.mp3",
            options: [{ text: "Pokračovat", type: "next-slide" }],
          },
        },
      ],
    },
    {
      kateText: "Zaujala jsem Vás? Najdete mě v aplikaci ČSOB Smart.",

      mp3: "story/records/student3_v2.mp3",
      image: "story/zubar/9.jpg",
      content: `#### Žádáte Kate o pomoc

Kate po vašem potvrzení platbu odesílá během několika sekund. Pacient je v klidu, manželka má situaci vyřešenou. Krize zažehnána. Nyní se jen zbavit zbytku zubního kazu a těšit se doma na skvělou večeři. `,
      options: defaultOptionsKate,
    },
  ],
};
