import { useEffect, useState, createContext, useContext } from "react";
import { addRecord } from "./utils";
interface ConnectionContext {
  startTracking: (type: string, value: any) => void;
  endTrackingCurrent: () => void;
  finishTrackingCurrent: (meta?: any) => void;
  // endTracking: (type: string, value: any) => void;
}

export interface AnalyticsData {
  type: string;
  value: any;
  start: number;
  end?: number;
  meta?: any;
  finished?: boolean;
}

// @ts-ignore
const Analytics = createContext<ConnectionContext>();

const AnalyticsProvider = ({ children }: any) => {
  const [usageTracking, setUsageTracking] = useState<AnalyticsData | null>(null);
  const [currentlyTracking, setCurrentlyTracking] = useState<AnalyticsData | null>(null);
  /*
  funkce startTracking (id, name, category, value)
  funkce userLeaved(id), this should save time of leaving and track data. Then save it to storage
  funkce userFinisthed(id), this should save time of finishing and track data. Then save it to storage
  funkce userReturned, this should save time of returning and track data. Then save it to storage

  v grafane zajimave grafy
  Kolacovy graf - kolik lidi dokoncilo, kolik lidi leavlo
  Bar graf - jak dlouho lidi trvalo dokoncit jednotlive casti
  Pexeso kolacovy graf - kolik lidi dokoncilo pexeso, kolik lidi leavlo
  Line graf - casy kdy jsou lide na tabletu aktivni/neaktivni


  TYPES: 
  - session (start,end)
  - activity (start,end, name, category, value)
  - clicks (start, name)
  */

  function startTracking(type: string, value: any) {
    // save to storage
    const data = {
      type,
      value,
      start: new Date().getTime(),
    };
    if (type !== "usage") {
      setCurrentlyTracking(data);
    } else {
      setUsageTracking(data);
    }
  }

  function endTrackingCurrent() {
    // save to storage
    if (currentlyTracking) {
      const data = {
        type: currentlyTracking.type,
        value: currentlyTracking.value,
        start: currentlyTracking.start,
        end: new Date().getTime(),
      };
      addRecord(data);
      setCurrentlyTracking(null);
    }

    if (usageTracking) {
      const data = {
        type: usageTracking.type,
        value: usageTracking.value,
        start: usageTracking.start,
        end: new Date().getTime(),
      };
      addRecord(data);
      setUsageTracking(null);
    }
  }

  function finishTrackingCurrent(meta?: any) {
    // save to storage
    if (currentlyTracking) {
      const data = {
        type: currentlyTracking.type,
        value: currentlyTracking.value,
        start: currentlyTracking.start,
        end: new Date().getTime(),
        meta,
        finished: true,
      };
      addRecord(data);
      setCurrentlyTracking(null);
    }
  }

  // function endTracking(type: string, value: any) {
  //   // save to storage
  //   const data = {
  //     type,
  //     value,
  //     start: new Date().getTime(),
  //     end: new Date().getTime(),
  //   };
  //   addRecord(data);
  // }

  return (
    <Analytics.Provider
      value={{
        startTracking,
        endTrackingCurrent,
        finishTrackingCurrent,
        // endTracking,
      }}
    >
      {children}
    </Analytics.Provider>
  );
};

const useAnalytics = () => {
  const context = useContext(Analytics);
  if (context === undefined) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }
  return context;
};

export { AnalyticsProvider, useAnalytics };
