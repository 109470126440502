import _ from "lodash";

export function uniqRandom(times: number, ...args: any) {
  const set = new Set();

  while (times > 0) {
    const rand = _.random(...args);

    if (!set.has(rand)) {
      set.add(rand);
      times--;
    }
  }

  return Array.from(set);
}
// console.log(uniqRandom(8, 1, 10));
