import { defaultOptionsKate } from "./zubar";

export const zpevacka = {
  title: "Zpěvačka",
  storyNumber: 4,
  story: [
    {
      content: `Jste zpěvačkou v malé studentské kapele. Za pár hodin vás čeká večerní koncert. Volá vám zvukař Karel. Říká, že mikrofon vůbec nefunguje a neví, co má dělat.

Jak se zachováte?`,
      image: "story/zpevacka/1.jpg",
      // mp3: "story/records/1b.mp3",
      options: [
        {
          text: "Koncert raději zrušíte",
          type: "story",
          story: {
            content: `## Říkáte, že koncert bude lepší zrušit

Zvukař se zděsí. Zrušení by vás stálo spoustu peněz. Musíte si po cestě najít nějaký obchod s elektrem nebo hudebninami. Přes to nejede vlak!`,
            image: "story/zpevacka/pult.png",
            options: [
              {
                text: "Zpět na výběr možností",
                type: "back",
              },
            ],
          },
        },
        {
          text: "Zkusíte rychle sehnat nový mikrofon",
          type: "story",
          story: {
            content: `## Naštěstí máte obchod s hudebním náčiním po cestě!

Už máte skoro vybraný vhodný mikrofon. Ale ouha! Je dost drahý, na účtu nemáte tolik peněz. Nedá se nic dělat, musíte se složit s klukama z kapely.`,
            kateText: "Nebojte, i tady vám umím pomoct. Stačí, když si mě otevřete na mobilu v aplikaci Smart.",
            mp3: "story/records/zpevacka1.mp3",
            image: "story/zpevacka/mic.png",
            options: [
              {
                text: "Na mikrofon se složíte s kapelou",
                type: "next",
              },
            ],
          },
        },
      ],
    },
    {
      content: `Nic nepočítejte, Kate to udělá za vás. Řekněte nebo písněte, mezi kolik lidí chcete platbu rozdělit: „Rozděl platbu mezi 4 lidi.“ Od Kate dostanete QR kód a je to!`,
      options: [
        {
          text: "Ukončit ukázku Kate",
          type: "next",
        },
      ],
      chat: {
        src: "story/QR.webm",
      },
    },
    {
      content: `Kate všechno spočítala a rozdělila částku. Výsledný QR kód s detaily platby vám ukazála na obrazovce telefonu. Snadné!

Peníze jsou na vašem účtu během chvilky. Koncert jste stihla akorát a mikrofon funguje skvěle.`,
      kateText: "Docela pecka, co říkáte? Více o mě najdete v aplikaci ČSOB Smart.",
      mp3: "story/records/zpevacka3 - konec.mp3",
      // mp3_2: "story/records/zpevacka3 - konec.mp3",

      image: "story/zpevacka/singer.jpg",
      options: defaultOptionsKate,
    },
  ],
};
