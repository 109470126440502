export const student = {
  title: "Student Robert",
  storyNumber: 1,
  story: [
    {
      // kateText: "Snad večer dobře dopadne.",
      // mp3: "story/records/1a.mp3",
      chat: false,
      image: "story/student/1.jpg",
      content: `Dnes jste pozval Karin na rande do vyhlášené restaurace.`,
      options: [{ text: "Pokračovat", type: "next" }],
      delay: 3000,
    },
    {
      image: "story/student/2.jpg",

      mp3: "story/records/student1.mp3",
      kateText: "Bez obav! S touto situací vám snadno pomůžu.",
      content: `Rande probíhá skvěle. Přichází čas placení. Chcete platit kartou a najednou si nemůžete vybavit svůj PIN!`,
      options: [
        {
          text: "Zavolat mamce o radu",
          type: "story",
          story: {
            image: "story/student/3.jpg",
            content: `Máma to nebere a Karin zahlédla, že voláte mamince. Ehm… trapas.`,
            options: [{ text: "Zpět na výběr možností", type: "back" }],
          },
        },
        {
          text: "Požádat Kate o rychlou pomoc",
          type: "story",
          story: {
            // mp3: "story/records/1a.mp3",
            image: "story/student/3.jpg",
            content: `Na mobilu otevřete Smart, klepnete na logo Kate a řeknete: „Kate, jaký je můj PIN?“`,
            options: [
              {
                text: "Ukončit ukázku Kate",
                type: "next",
              },
            ],
            mp3: "story/records/student2.mp3",
            kateText: "Naštěstí vím přesně, kde PIN najít. Jdeme na to?",
            chat: {
              src: "story/PIN.webm",
            },

            delay: 1000,
          },
        },
      ],
    },
    {
      content: `### Dobrá práce!

Kate vám ukázala PIN a platba proběhla úspěšně. Karin je nadšená a chce s vámi jít zítra do kina.`,
      image: "story/student/9.jpg",
      kateText: "Zaujala jsem Vás? Najdete mě v aplikaci ČSOB Smart.",
      mp3: "story/records/student3_v2.mp3",
      // mp3: "story/records/4a.mp3",

      options: [
        { text: "Zpět do hlavní nabídky", type: "exit" },
        { text: "Více o Kate", type: "kate" },
      ],
    },
  ],
};
